import React, { useEffect, useState } from 'react';
import { SearchByArticleData } from '@shared/model/Store/SearchDetails/types';
import { useSearchDetailsStore } from '@shared/model/Store/SearchDetails';
import { useFilterComponentStore } from '@shared/model/Store/FilterComponent';
import { EMPTY_ARRAY, EMPTY_STRING } from '@shared/constants/fallbacks';
import { isNumberRegex } from '@shared/constants/regex';
import { deliveries } from '@shared/constants/deliveries';
import { productFiltering } from '../lib/functions/productFiltering';
import { NUMBER_APPLICATIONS } from '../lib/constants/numberApplications';
import { FilterComponentView } from './FilterComponentView';
import { Brand, FilterComponentProps } from './types';

export const FilterComponent = ({
  isLoading,
  handleSelectedArticleClicked
}: FilterComponentProps) => {
  const [
    searchByArticleDetailsData,
    articleList,
    setArticleList,
    articleAnalogues,
    analoguePagination,
    articlePagination,
    setAnaloguesList,
    analoguesList,
    setArticleWithPriceList
  ] = useSearchDetailsStore(state => [
    state.searchByArticleDetailsData,
    state.articleList,
    state.setArticleList,
    state.articleAnalogues,
    state.analoguePagination,
    state.articlePagination,
    state.setAnaloguesList,
    state.analoguesList,
    state.setArticleWithPriceList
  ]);

  const [
    selectedDelivery,
    selectedDeliveryPeriod,
    day,
    availability,
    // filterValue, // TODO: Содержить выбранную сортировку (из выпадающего списка "По популярности" и т.д). Понадобится при интеграции сортировки "По популярности", "По возрастанию цены" и т.д
    brands,
    setSelectedDelivery,
    setDay,
    setAvailability,
    setFilterValue,
    setBrands,
    resetFilter
  ] = useFilterComponentStore(state => [
    state.selectedDelivery,
    state.selectedDeliveryPeriod,
    state.day,
    state.availability,
    // state.filterValue, // TODO: Содержить выбранную сортировку (из выпадающего списка "По популярности" и т.д). Понадобится при интеграции сортировки "По популярности", "По возрастанию цены" и т.д
    state.brands,
    state.setSelectedDelivery,
    state.setDay,
    state.setAvailability,
    state.setFilterValue,
    state.setBrands,
    state.resetFilter
  ]);

  const [showFilter, setShowFilter] = useState<boolean>(false);
  const [totalArticlePages, setTotalArticlePages] = useState(2);
  const [totalAnaloguesPages, setTotalAnaloguesPages] = useState(2);
  const [showArticlesAll, setShowArticlesAll] = useState(false);
  const [showAnaloguesAll, setShowAnaloguesAll] = useState(false);
  const [isFirstAllBtnForArticleClick, setIsFirstAllBtnForArticleClick] = useState(true);
  const [isFirstAllBtnForAnaloguesClick, setIsFirstAllBtnForAnaloguesClick] = useState(true);

  const handleBrands = (index: number) => {
    const newBrands = () => {
      return brands.map((brand, i) => {
        if (i === index) {
          return {
            ...brand,
            status: !brand.status
          };
        }

        return brand;
      });
    };

    setBrands(newBrands());
  };
  const handleSelectedDelivery = (value: number) => {
    setDay(null);
    setSelectedDelivery(value);
  };
  const handleDay = (value: string) => {
    setSelectedDelivery(20); // Добавляется любое число, не совпадающее с чекбоксами "Срок доставки", для его обнуления

    if (isNumberRegex.test(value) || value === EMPTY_STRING) {
      setDay(Number(value));
    }

    if (value === EMPTY_STRING) {
      setSelectedDelivery(Number.MAX_VALUE);
    }
  };
  const handleAvailability = (value: string) => {
    if (isNumberRegex.test(value) || value === EMPTY_STRING) {
      setAvailability(Number(value));
    }
  };
  const handleFilterValue = (value: string) => {
    setFilterValue(value);
  };
  const handleShowFilter = () => setShowFilter(!showFilter);
  const handleSearch = () => {
    if (searchByArticleDetailsData) {
      const filters = {
        day: selectedDeliveryPeriod,
        availability,
        brands
      };
      const filteredArticleList = productFiltering(searchByArticleDetailsData, filters);
      const filteredArticleAnalogues = productFiltering(articleAnalogues || EMPTY_ARRAY, filters);

      setAnaloguesList(filteredArticleAnalogues);
      setArticleList(filteredArticleList);
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);

    if (searchByArticleDetailsData) {
      const newBrands: Brand[] = []; // Использован обычный массив, а не EMPTY_ARRAY из-за необходимости мутации

      searchByArticleDetailsData.forEach((item: SearchByArticleData) => {
        const { brand, distributorId } = item;

        if (!newBrands.some(brandItem => brandItem.name === brand)) {
          newBrands.push({
            id: distributorId ?? 0,
            name: brand ?? EMPTY_STRING,
            value: brand ?? EMPTY_STRING,
            status: false
          });
        }
      });

      // TODO: Другого корректного обновления брендов пока нет, поэтому пока остается этот вариант
      const updateBrands = setTimeout(() => {
        setBrands(newBrands);
      }, 0);

      () => clearTimeout(updateBrands);
    }
    setAnaloguesList(articleAnalogues || EMPTY_ARRAY);
    setArticleList(
      searchByArticleDetailsData
        ? searchByArticleDetailsData.slice(0, NUMBER_APPLICATIONS)
        : EMPTY_ARRAY
    );

    setArticleWithPriceList(searchByArticleDetailsData ? searchByArticleDetailsData : EMPTY_ARRAY);

    resetFilter();
  }, [searchByArticleDetailsData, articleAnalogues]);

  return (
    <FilterComponentView
      analoguesList={analoguesList}
      analoguesPagination={analoguePagination}
      articleAnalogues={articleAnalogues}
      articleList={articleList}
      articlesPagination={articlePagination}
      availability={availability}
      brands={brands}
      day={day}
      deliveries={deliveries}
      dirty={true}
      handleAvailability={handleAvailability}
      handleBrands={handleBrands}
      handleDay={handleDay}
      handleFilterValue={handleFilterValue}
      handleSearch={handleSearch}
      handleSelectedArticleClicked={handleSelectedArticleClicked}
      handleSelectedDelivery={handleSelectedDelivery}
      handleShowFilter={handleShowFilter}
      isFirstAllBtnForAnaloguesClick={isFirstAllBtnForAnaloguesClick}
      isFirstAllBtnForArticleClick={isFirstAllBtnForArticleClick}
      isLoading={isLoading}
      replacementsList={searchByArticleDetailsData}
      searchByArticleDetailsData={searchByArticleDetailsData}
      selectedDelivery={selectedDelivery}
      setAnaloguesList={setAnaloguesList}
      setArticleList={setArticleList}
      setIsFirstAllBtnForAnaloguesClick={setIsFirstAllBtnForAnaloguesClick}
      setIsFirstAllBtnForArticleClick={setIsFirstAllBtnForArticleClick}
      setShowAnaloguesAll={setShowAnaloguesAll}
      setShowArticlesAll={setShowArticlesAll}
      setTotalAnaloguesPages={setTotalAnaloguesPages}
      setTotalArticlePages={setTotalArticlePages}
      showAnaloguesAll={showAnaloguesAll}
      showArticlesAll={showArticlesAll}
      showFilter={showFilter}
      totalAnaloguesPages={totalAnaloguesPages}
      totalArticlePages={totalArticlePages}
    />
  );
};
