import React, { useEffect, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { EMPTY_STRING } from '@shared/constants/fallbacks';
import { useSearch } from '@shared/hooks/use-search';
import { SearchWithClearButton } from '@shared/components/SearchWithClearButton/SearchWithClearButton';
import { pathConfig } from '@shared/constants/pathConfig';
import { ArticleTipsResponse, SearchByArticleData } from '@shared/model/Store/SearchDetails/types';
import { useScreenSizeBreakpoints } from '@shared/hooks/use-screen-size-breakpoints';
import { useCarCatalog } from '@shared/hooks/use-car-catalog';
import { useSearchDetailsStore } from '@shared/model/Store/SearchDetails';
import { INITIAL_PAGE, INITIAL_NUMBER_OF_SHOWN } from '@shared/constants/consts';
import { breadcrumbsLinksForCatalogItemPrice } from '../lib/breadcrumbsLinksForCatalogItemPrice';
import { CatalogItemPriceByArticlePageView } from './CatalogItemPriceByArticlePageView';

export const CatalogItemPriceByArticlePage = () => {
  const { category, product, brand, item_product } = useParams();
  const isSearchArticlePage = window.location.pathname.includes('/byarticle/');

  const navigate = useNavigate();

  const [articleList, isLoadingSearchByArticleDetailsData, setSelectedArticle] =
    useSearchDetailsStore(state => [
      state.articleList,
      state.isLoadingSearchByArticleDetailsData,
      state.setSelectedArticle
    ]);

  const [searchValue, setSearchValue] = useState(item_product || EMPTY_STRING);
  const [currentSearchValue, setCurrentSearchValue] = useState(item_product || EMPTY_STRING);
  const [isFocused, setIsFocused] = useState(false);
  const [urlDropdownItem, setUrlDropdownItem] = useState(EMPTY_STRING);

  const {
    setArticleTip,
    getArticleTips,
    articleTips,
    getSearchDetailsByArticleOnlyData,
    isLoadingArticle,
    getArticleOnlyAnaloguesData
  } = useSearch(searchValue);

  const { isMobile } = useScreenSizeBreakpoints();

  const { selectedModel, setSelectedModel } = useCarCatalog();

  const dropdownRef = useRef<HTMLDivElement>(null);

  const isOpenDropdown = isFocused && articleTips.length > 0;

  const handleDropdownItemClick = (tip: ArticleTipsResponse) => {
    setArticleTip(tip);
    setSearchValue(tip.number);
    setCurrentSearchValue(tip.number);
    setSelectedModel({
      ...selectedModel,
      name: tip.brand
    });

    navigate(`${urlDropdownItem}/${tip.brand}/${tip.number}`);
  };

  const handleButtonClick = () => {
    navigate(`${urlDropdownItem}/${articleTips[0].brand}/${articleTips[0].number}`);
    setIsFocused(false);
  };

  const handleSearchChange = (value: string) => {
    setArticleTip(null);
    setSearchValue(value);

    value.length > 0 && getArticleTips(value);
  };

  const searchWithClearButton = SearchWithClearButton(
    searchValue,
    handleSearchChange,
    handleButtonClick
  );

  const handleInputFocus = () => {
    setIsFocused(true);
  };
  const handleInputBlur = () => {
    setTimeout(() => {
      setIsFocused(false);
    }, 150);
  };

  const goBack = () => navigate(pathConfig.MainPath);

  useEffect(() => {
    const newUrlDropdown = isSearchArticlePage
      ? '/byarticle/search'
      : `/catalog/${category}/${product}`;

    setUrlDropdownItem(newUrlDropdown);
    setSearchValue(item_product || EMPTY_STRING);
    setCurrentSearchValue(item_product || EMPTY_STRING);

    if (item_product && brand) {
      getSearchDetailsByArticleOnlyData(item_product, brand, INITIAL_PAGE, INITIAL_NUMBER_OF_SHOWN);
      getArticleOnlyAnaloguesData(item_product, brand, INITIAL_PAGE, INITIAL_NUMBER_OF_SHOWN);
    }
  }, [brand, item_product]);

  const handleSelectedArticleClicked = (selectedItem: SearchByArticleData) => {
    setSelectedArticle(selectedItem);
  };

  return (
    <CatalogItemPriceByArticlePageView
      articleTips={articleTips}
      currentSearchValue={currentSearchValue}
      dropdownRef={dropdownRef}
      goBack={goBack}
      handleButtonClick={handleButtonClick}
      handleDropdownItemClick={handleDropdownItemClick}
      handleInputBlur={handleInputBlur}
      handleInputFocus={handleInputFocus}
      handleSearchChange={handleSearchChange}
      handleSelectedArticleClicked={handleSelectedArticleClicked}
      isFocused={isFocused}
      isLoadingArticle={isLoadingArticle}
      isLoadingSearchByArticleDetailsData={isLoadingSearchByArticleDetailsData}
      isMobile={isMobile}
      isOpenDropdown={isOpenDropdown}
      resultLength={articleList?.length || 0}
      searchValue={searchValue}
      searchWithClearButton={searchWithClearButton}
      breadcrumbsLinks={breadcrumbsLinksForCatalogItemPrice(
        item_product || EMPTY_STRING,
        isSearchArticlePage
      )}
    />
  );
};
