import { IconedText } from '@uremont/ui-kit';
import {
  NotesPen,
  UserCircle,
  Sale,
  Cart,
  Box,
  Chat,
  Service,
  Star,
  NotesPad,
  GraphUp,
  Wallet
} from '@uremont/ui-icons-v2';
import { uremontLinks } from '@shared/constants/links';
import styles from './ProfileMenuService.module.scss';

export const ProfileMenuService = () => (
  <div className={styles.wrapper}>
    <a className={styles.link} href={uremontLinks.serviceBids}>
      <IconedText icon={NotesPen} iconSize={24} textType='bodyTightRegular'>
        Заявки
      </IconedText>
    </a>
    <a className={styles.link} href={uremontLinks.serviceMyService}>
      <IconedText icon={Service} iconSize={24} textType='bodyTightRegular'>
        Мой автосервис
      </IconedText>
    </a>
    <a className={styles.link} href={uremontLinks.serviceStat}>
      <IconedText icon={GraphUp} iconSize={24} textType='bodyTightRegular'>
        Аналитика
      </IconedText>
    </a>
    <a className={styles.link} href={uremontLinks.serviceFinanses}>
      <IconedText icon={Wallet} iconSize={24} textType='bodyTightRegular'>
        Мой баланс
      </IconedText>
    </a>
    <a className={styles.link} href={uremontLinks.autopartsOrders}>
      <IconedText icon={Cart} iconSize={24} textType='bodyTightRegular'>
        Корзина
      </IconedText>
    </a>
    <a className={styles.link} href={uremontLinks.autopartsCart}>
      <IconedText icon={Box} iconSize={24} textType='bodyTightRegular'>
        Заказы
      </IconedText>
    </a>
    <a className={styles.link} href={uremontLinks.serviceChat}>
      <IconedText icon={Chat} iconSize={24} textType='bodyTightRegular'>
        Сообщения
      </IconedText>
    </a>
  </div>
);
