import React from 'react';
import { Button } from '@uremont/erp-ui';

import { ReactComponent as IconFilter } from '@assets/IconFilter.svg';
import { dataTestId } from '@shared/constants/dataTestId';
import { Filter } from '@shared/components/FilterComponent/lib/Filter';
import { FilterPageList } from '@shared/components/FilterComponent/lib/FilterPageList';

import { FilterPageViewProps } from './types';
import styles from './styles.module.scss';

export const FilterComponentView = ({
  // TODO: Пропсы для Срок доставки пока скрыты, так как в будущем может потребуется вернуть этот функционал
  // deliveries,
  // selectedDelivery,
  // handleSelectedDelivery,
  // handleDay,
  brands,
  handleBrands,
  day,
  availability,
  handleAvailability,
  handleSearch,
  dirty,
  // handleFilterValue, // TODO: Скрыт, пока не будет готов метод для сортировки на беке
  articleList,
  showFilter,
  handleShowFilter,
  isLoading,
  // articleAnalogues, // TODO: Аналоги скрыты по задаче
  handleSelectedArticleClicked,
  articlesPagination,
  // analoguesPagination, // TODO: Аналоги скрыты по задаче
  totalArticlePages,
  // totalAnaloguesPages, // TODO: Аналоги скрыты по задаче
  setTotalArticlePages,
  // setTotalAnaloguesPages, // TODO: Аналоги скрыты по задаче
  setArticleList,
  // setAnaloguesList, // TODO: Аналоги скрыты по задаче
  // analoguesList, // TODO: Аналоги скрыты по задаче
  // showAnaloguesAll, // TODO: Аналоги скрыты по задаче
  // setShowAnaloguesAll, // TODO: Аналоги скрыты по задаче
  showArticlesAll,
  setShowArticlesAll,
  // isFirstAllBtnForAnaloguesClick, // TODO: Аналоги скрыты по задаче
  isFirstAllBtnForArticleClick,
  // setIsFirstAllBtnForAnaloguesClick, // TODO: Аналоги скрыты по задаче
  setIsFirstAllBtnForArticleClick,
  searchByArticleDetailsData
}: FilterPageViewProps) => {
  return (
    <div className={styles.filterPage} data-testid={dataTestId.FILTER_PAGE_VIEW}>
      <div className={styles.filterPage_content}>
        <Filter
          availability={availability}
          brands={brands}
          day={day}
          dirty={dirty}
          handleAvailability={handleAvailability}
          handleBrands={handleBrands}
          handleSearch={handleSearch}
          handleShowFilter={handleShowFilter}
          isLoading={isLoading}
          showFilter={showFilter}
          // TODO: Пропсы для Срок доставки пока скрыты, так как в будущем может потребуется вернуть этот функционал
          // deliveries={deliveries}
          // handleDay={handleDay}
          // handleSelectedDelivery={handleSelectedDelivery}
          // selectedDelivery={selectedDelivery}
        />
        <div className={styles.filterPage_list}>
          <div className={styles.filterPage_list_topWrap}>
            {/* TODO: Select скрыт, пока не будет готов метод для сортировки на беке  */}
            {/* <CustomSelect
              className={styles.filterPage_list_select}
              data-testid={dataTestId.FILTER_PAGE_FILTER_SELECT}
              defaultValue={filterOptions[0]}
              onChange={handleFilterValue}
              options={filterOptions}
            /> */}
            <Button
              className={styles.filterPage_filterShowBtn}
              onClick={handleShowFilter}
              variant='control'
            >
              <IconFilter />
            </Button>
          </div>
          <FilterPageList
            handleSelectedArticleClicked={handleSelectedArticleClicked}
            isFirstAllBtnClick={isFirstAllBtnForArticleClick}
            isLoading={isLoading}
            originDetailsData={searchByArticleDetailsData}
            pagination={articlesPagination}
            products={articleList}
            setIsFirstAllBtnClick={setIsFirstAllBtnForArticleClick}
            setProductsList={setArticleList}
            setShowAll={setShowArticlesAll}
            setTotalPages={setTotalArticlePages}
            showAll={showArticlesAll}
            title='Запрашиваемый артикул'
            totalPages={totalArticlePages}
          />
          {/*//TODO: Раскомментировать, когда будет известно по каким параметрам будет разделение по ключу title*/}
          {/*<FilterPageList products={replacementsList} title={'Оригинальные замены'} />*/}
          {/*
          //TODO: Временно скрыто
          {articleAnalogues && articleAnalogues.length > 0 && (
            <FilterPageList
              handleSelectedArticleClicked={handleSelectedArticleClicked}
              isFirstAllBtnClick={isFirstAllBtnForAnaloguesClick}
              isLoading={isLoading}
              originDetailsData={articleAnalogues}
              pagination={analoguesPagination}
              products={analoguesList}
              setIsFirstAllBtnClick={setIsFirstAllBtnForAnaloguesClick}
              setProductsList={setAnaloguesList}
              setShowAll={setShowAnaloguesAll}
              setTotalPages={setTotalAnaloguesPages}
              showAll={showAnaloguesAll}
              title='Аналоги'
              totalPages={totalAnaloguesPages}
            />
          )} */}
        </div>
      </div>
    </div>
  );
};
