import { pathConfig } from '@shared/constants/pathConfig';
import { EMPTY_STRING } from '@shared/constants/fallbacks';

export const UREMONT_URL = process.env.REACT_APP_UREMONT_URL ?? EMPTY_STRING;
export const UREMONT_MAIN_URL = process.env.REACT_APP_UREMONT_MAIN_URL ?? EMPTY_STRING;
export const UREMONT_MAP_URL = process.env.REACT_APP_UREMONT_MAP_URL ?? EMPTY_STRING;
export const INSURANCE_URL = process.env.REACT_APP_INSURANCE_URL ?? EMPTY_STRING;
export const UREMONT_CUSTOMER_URL = process.env.REACT_APP_UREMONT_CUSTOMER_URL ?? EMPTY_STRING;
export const UREMONT_SERVICE_URL = process.env.REACT_APP_UREMONT_SERVICE_URL ?? EMPTY_STRING;
export const CORPARK_URL = process.env.REACT_APP_CORPARK_URL ?? EMPTY_STRING;
export const LOYALTY_URL = process.env.REACT_APP_LOYALTY_URL ?? EMPTY_STRING;
export const FOND_URL = process.env.REACT_APP_FOND_URL ?? EMPTY_STRING;
export const RAFFLE_URL = process.env.REACT_APP_RAFFLE_URL ?? EMPTY_STRING;
export const FRANCHISE_URL = process.env.REACT_APP_FRANCHISE_URL ?? EMPTY_STRING;
export const FRONT_UREMONT_SERVICE_URL =
  process.env.REACT_APP_FRONT_UREMONT_SERVICE_URL ?? EMPTY_STRING;

export const uremontLinks = {
  mainUremont: UREMONT_MAIN_URL,
  newBid: `${UREMONT_URL}/newbid`,
  frontUremontServiceUrl: FRONT_UREMONT_SERVICE_URL,
  map: `${UREMONT_MAP_URL}/map`,
  autoparts: pathConfig.MainPath,
  autopartsOrders: pathConfig.HistoryOrdersPath,
  autopartsCart: pathConfig.BasketPath,
  eosago: `${INSURANCE_URL}/eosago`,
  insurance: INSURANCE_URL,
  insuranceKasko: `${INSURANCE_URL}/kasko`,
  insuranceEipoteka: `${INSURANCE_URL}/eipoteka`,
  insuranceAgents: `${INSURANCE_URL}/agents`,
  faq: `${UREMONT_URL}/faq`,
  autoparks: CORPARK_URL,
  registerService: `${UREMONT_MAIN_URL}/register-service`,
  agreement: `${UREMONT_MAIN_URL}/agreement`,
  agreementBel: `${UREMONT_MAIN_URL}/agreement-bel`,
  offer: `${UREMONT_MAIN_URL}/offer`,
  offerBel: `${UREMONT_MAIN_URL}/offer-belarus`,
  offerSTO: `${UREMONT_URL}/static/Offer_march_2020.pdf`,
  offerSTOBel: `${UREMONT_MAIN_URL}/offer-sto-belarus`,
  privacy: `${UREMONT_URL}/privacy`,
  privacyBel: `${UREMONT_MAIN_URL}/privacy-bel`,
  partsOffer: `${UREMONT_URL}/static/Offer_january_2021.pdf`,
  howItWorks: `${UREMONT_MAIN_URL}/how-it-works`,
  contacts: `${UREMONT_URL}/contacts`,
  charity: FOND_URL,
  documentation: `${UREMONT_MAIN_URL}/documents`,
  vk: 'https://vk.com/uremont_com',
  youtube: 'https://www.youtube.com/channel/UCwxYP-9mGpnPbNyOr5HoS7Q',
  appStore: 'https://apps.apple.com/ru/app/uremont-com/id1262343094#?platform=iphone',
  googlePlay: 'https://play.google.com/store/apps/details?id=com.uremont&hl=ru&gl=US',
  // TODO - раскомметировать изменения, при возвращении пункта "Автомасла" в меню хедера и в слайдер баннера
  // silkOil: 'https://silkoil.pro/',
  loyalty: LOYALTY_URL,
  raffle: RAFFLE_URL,
  franchising: `${FRANCHISE_URL}/franchising`,
  serviceBids: `${UREMONT_SERVICE_URL}/cabinet/service/bids`,
  serviceProfile: `${UREMONT_SERVICE_URL}/cabinet/service/profile`,
  serviceMyService: `${UREMONT_SERVICE_URL}/cabinet/service/profile`,
  serviceReviews: `${UREMONT_SERVICE_URL}/cabinet/service/reviews`,
  serviceTariffs: `${UREMONT_SERVICE_URL}/cabinet/service/tariffs`,
  serviceStat: `${UREMONT_SERVICE_URL}/cabinet/service/stat`,
  servicePromo: `${UREMONT_SERVICE_URL}/cabinet/service/promo`,
  serviceFinanses: `${UREMONT_SERVICE_URL}/cabinet/service/finances`,
  serviceChat: `${UREMONT_SERVICE_URL}/cabinet/service/chat`,
  customerDashboard: `${UREMONT_CUSTOMER_URL}/cabinet/customer/main`,
  customerProfile: `${UREMONT_CUSTOMER_URL}/cabinet/customer/profile`,
  customerPromo: `${UREMONT_CUSTOMER_URL}/cabinet/customer/promo`,
  customerChosen: `${UREMONT_CUSTOMER_URL}/cabinet/customer/selected-services`,
  customerBids: `${UREMONT_CUSTOMER_URL}/cabinet/customer/bids`,
  customerCars: `${UREMONT_CUSTOMER_URL}/cabinet/customer/cars`,
  customerReports: `${UREMONT_CUSTOMER_URL}/cabinet/customer/reports`,
  customerChat: `${UREMONT_CUSTOMER_URL}/cabinet/customer/chat`
};
