import React from 'react';
import { Text } from '@uremont/erp-ui';

import { SearchProgressBar } from '@pages/Main/lib/SearchProgressBar';
// import { RequestBanner } from '@shared/components/RequestBanner';
import { dataTestId } from '@shared/constants/dataTestId';

// import { CustomModal } from '@shared/components/CustomModal/CustomModal';
// import { BANNER_BUTTON_TEXT } from '@shared/constants/ texts';
import { NoInfoView } from '@shared/components/NoInfo/ui/NoInfoView';
import { SearchByArticleInput } from '@shared/components/SearchByArticleInput/SearchByArticleInput';
import { GRZInput } from '../lib/GRZInput';

import { SearchProps } from './types';
import styles from './styles.module.scss';

export const SearchView = ({
  // Icon,
  handleSearchGRZChange,
  handleSearchVINChange,
  isSearchByGRZ,
  placeholderText,
  renderSearchLinks,
  searchInputButton,
  searchVINValue,
  showAutoVinNumberInput,
  showModelAutoSearch,
  // isOpenModal,
  toggleModal,
  // children,
  handleInputBlur,
  handleInputFocus,
  dropdownRef,
  isFocused,
  articleTips,
  isLoadingArticle,
  handleButtonClick,
  isOpenDropdown,
  handleDropdownItemClick,
  isEmptyDataShow,
  isMobile,
  goBack,
  isGRZError
}: SearchProps) => {
  return (
    <div className={styles.contentWrapper} data-testid={dataTestId.SEARCH_VIEW}>
      <div className={styles.navBarLinksContainer}>
        <div className={styles.searchLinks}>{renderSearchLinks()}</div>
      </div>
      {showAutoVinNumberInput &&
        (isSearchByGRZ ? (
          <GRZInput
            action={searchInputButton}
            handleButtonClick={handleButtonClick}
            onChange={handleSearchGRZChange}
            placeholder={placeholderText}
          />
        ) : (
          <SearchByArticleInput
            articleTips={articleTips}
            classNameInput={styles.input}
            dropdownRef={dropdownRef}
            goBack={goBack}
            handleButtonClick={handleButtonClick}
            handleDropdownItemClick={handleDropdownItemClick}
            handleInputBlur={handleInputBlur}
            handleInputFocus={handleInputFocus}
            handleSearchChange={handleSearchVINChange}
            isFocused={isFocused}
            isLoadingArticle={isLoadingArticle}
            isMobile={isMobile}
            isOpenDropdown={isOpenDropdown}
            searchValue={searchVINValue}
            searchWithClearButton={searchInputButton}
          />
        ))}
      {((!isSearchByGRZ && isEmptyDataShow) || isGRZError) && (
        <NoInfoView isGRZError={isGRZError} onClick={toggleModal} />
      )}
      {showModelAutoSearch && (
        <div className={styles.searchAutoContainer}>
          <Text className={styles.searchTitle} variant='headline-L'>
            Поиск по марке/модели автомобиля
          </Text>
          <SearchProgressBar />
        </div>
      )}
      {/* <RequestBanner // Временно скрыт
        Icon={Icon}
        buttonText={BANNER_BUTTON_TEXT}
        onPress={toggleModal}
        subTitle='Подберем для вашего авто только самое нужное'
        title='Оставьте заявку на поиск запчастей'
      /> */}
      {/* <CustomModal // Временно скрыт
        children={children}
        isOpen={isOpenModal}
        title='Заявка на подбор запчастей'
        toggleModal={toggleModal}
        width='xs'
      /> */}
    </div>
  );
};
